.language-dropdown {
    position: relative;
    margin: 10px 10px;
    display: flex;
    z-index: 1;
    width: 50px;
    height: 50px;

    .language-icon {
        z-index: 10;
        position: absolute;
        top: 30%;
    }

    .app-header-right__language {
        margin-left: 5px;
        display: flex;
        color: white;
        z-index: 11;
        justify-content: right;
        padding: 0 25px;
        align-items: center;

        p {
            margin: 0;
            height: fit-content;
        }
    }

    .arrow-down-white {
        margin: 5px;
        z-index: 1;
        position: absolute;
        top: 30%;
        left: 100%;
    }
}