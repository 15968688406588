@use '@app/scss/breakpoint' as *;

.admin-auth {
  .left-header {
    float: left;
    width: fit-content;
    height: inherit;
    display: flex;
    align-items: center;

    h1 {
      line-height: 0;
    }
  }

  .right-header {
    float: right;
    width: fit-content;
    height: inherit;
    display: flex;
    align-items: center;

    .register {
      @include mobile() {
        display: none;
      }

      .header-button {
        background-color: #fcfcfc;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        border-radius: 11px;
        width: 124px;
        height: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
      }
    }
    .language-dropdown {
      @include mobile() {
        display: none;
      }
    }
  }

  .menu-mobile {
    display: none;

    @include mobile() {
      display: block;
      position: absolute;
      left: 15px;
    }
  }

  .ant-drawer-close {
    .menu-icon {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  }

  .ant-drawer-body {
    background: #4b6385;

    .header-button {
      background-color: #fcfcfc;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      border-radius: 11px;
      width: 124px;
      height: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
    }
  }

  .footer-banner {
    background: #4b6385 !important;
  }
}
